<template>
  <div
    class="review-component-container"
    :style="`--star-color: ${colorCodes.iconColor}; --review-input-color: ${
      colorCodes.titleColor
    }; ${isCompact && !isMobile ? 'margin-left: 74px;' : ''}`"
  >
    <div v-if="!loadingReviews">
      <div
        v-if="isAuthorizedToChangeReviewStatus"
        class="multiple-ratings-container"
      >
        <rating-summery
          v-if="ratings.allRatingAvg"
          title="Overall Rating"
          :avgCount="ratings.allRatingAvg"
          :totalRatings="ratings.allRatingCount"
          :overview="summery.allSummery"
          style="margin-right: 20px"
          :colorCodes="colorCodes"
        />
        <rating-summery
          v-if="ratings.publicRatingAvg"
          title="Public Rating"
          :avgCount="ratings.publicRatingAvg"
          :totalRatings="ratings.publicRatingCount"
          :overview="summery.publicSummery"
          :colorCodes="colorCodes"
        />
      </div>
      <div v-else>
        <rating-summery
          v-if="ratings.publicRatingAvg"
          title="Rating Overview"
          :avgCount="ratings.publicRatingAvg"
          :totalRatings="ratings.publicRatingCount"
          :overview="summery.publicSummery"
          :colorCodes="colorCodes"
        />
      </div>
    </div>
    <div v-if="isAddReviewVisible">
      <div v-if="!newReviewWriting" class="write-review-button">
        <ui-button
          class="primary-outlined short"
          @click="startReviewWriting"
          title="Write a Review"
          :customStyles="{
            backgroundColor: `${colorCodes.backgroundColor} !important`,
            borderColor: `${colorCodes.titleColor} !important`,
            color: `${colorCodes.titleColor} !important`,
          }"
        />
      </div>
      <v-form
        v-else
        ref="addReviewForm"
        class="add-review-form"
        :style="`width: ${isMobile || isCompact ? '100%' : '50%'};`"
      >
        <div class="rating-container">
          <v-rating v-model="newReview.rating" class="v-rating-add"></v-rating>
          <v-text-field
            style="display: none"
            v-model="newReview.rating"
            type="number"
            :rules="[rules.rating]"
          />
          <span
            v-if="newReview.rating"
            class="rating-details"
            :style="{ color: colorCodes.titleColor }"
            >{{ `${newReview.rating}/5 Stars` }}</span
          >
        </div>
        <div class="additional-inputs">
          <div class="custom-validation-message">
            {{
              onceValidated && !newReview.rating ? ratingRequiredMessage : ""
            }}
          </div>
          <v-text-field
            v-model="newReview.name"
            type="text"
            label="Name"
            :rules="[rules.requiredName, rules.maxLengthName]"
            class="text-field"
            outlined
            dense
          />
          <v-text-field
            v-model="newReview.email"
            type="email"
            label="Email"
            :rules="[rules.requiredEmail, rules.email, rules.maxLengthEmail]"
            class="text-field"
            hint="Your email will not be visible to any third parties. It is collected only for internal purposes."
            persistent-hint
            outlined
            dense
          />
          <div class="text-area">
            <v-textarea
              v-model="newReview.comment"
              label="Write your review"
              :rules="[rules.maxLengthComment]"
              class="text-field"
              @input="textAreaCounter"
              counter
              no-resize
              outlined
            />
          </div>
          <v-checkbox
            v-model="newReview.is_display_name_as_anonymous"
            label="Post anonymously (Your name and email will not be visible to public)"
            class="anonymous-checkbox"
            :color="colorCodes.titleColor"
            dense
          />
          <div v-if="conf.addCaptcha">
            <vue-recaptcha
              :sitekey="conf.captchaKey"
              :loadRecaptchaScript="true"
              ref="recaptcha"
              @verify="onCaptchaVerified"
              @expired="onCaptchaExpired"
            ></vue-recaptcha>
            <div class="custom-validation-message">
              {{
                onceValidated && !this.newReview.recaptcha
                  ? captchaRequiredMessage
                  : ""
              }}
            </div>
          </div>
        </div>
        <div class="submitButtonContainer">
          <ui-button
            class="primary long"
            @click="submitReview"
            title="Submit"
            :customStyles="{
              backgroundColor: `${colorCodes.titleColor} !important`,
              borderColor: `${colorCodes.titleColor} !important`,
              color: `${colorCodes.backgroundColor} !important`,
            }"
          />
        </div>
      </v-form>
    </div>
    <div
      class="review-list-container"
      :style="`border-top: ${
        reviews && reviews.length ? `1px solid ${reviewsDividerColor}` : 'unset'
      };`"
    >
      <div
        v-for="review in reviews"
        :key="review.id"
        class="review-container"
        :style="`flex-direction: ${
          isMobile ? 'column' : 'row'
        }; border-bottom: 1px solid ${reviewsDividerColor};`"
      >
        <div class="rating-item">
          <v-rating
            v-model="review.rating"
            class="v-rating-add v-rating-read-only"
            readonly
          ></v-rating>
          <div class="review-name" :style="{ color: reviewTimestampColor }">
            {{
              "by " + review.name + (review.email ? " : " + review.email : "")
            }}
          </div>
          <div class="review-comment" :style="{ color: colorCodes.titleColor }">
            {{ review.comment }}
          </div>
          <div class="review-date" :style="{ color: reviewTimestampColor }">
            {{ $moment(review.created_at).format("HH:mm Do MMMM Y") }}
          </div>
        </div>
        <div
          v-if="isAuthorizedToChangeReviewStatus"
          class="action-panel-container"
        >
          <div class="action-panel">
            <v-radio-group
              v-model="review.is_private"
              row
              @change="updateReviewStatus(review)"
            >
              <v-radio label="Private" :value="1"></v-radio>
              <v-radio label="Public" :value="0"></v-radio>
            </v-radio-group>
            <div class="delete-button" @click="deleteReview(review)">
              <v-icon class="delete-icon">delete</v-icon>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loadingReviews" class="loading-indicator">
        <v-progress-circular
          indeterminate
          color="#473068"
        ></v-progress-circular>
      </div>
      <div
        v-if="preview"
        class="no-reviews"
        :style="{ color: reviewTimestampColor }"
      >
        Customer feedback will be listed here.
      </div>
      <div v-else>
        <div
          v-if="!loadingReviews && !reviews.length"
          class="no-reviews"
          :style="{ color: reviewTimestampColor }"
        >
          No reviews yet
        </div>
        <pagination
          :iconColor="colorCodes.titleColor"
          :backgroundColor="paginationBackgroundColor"
          :current-page="reviewsPagination.currentPage"
          :total-count="reviewsPagination.totalCount"
          @load-page="totalCount"
          v-if="reviews.length"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { email } from "vuelidate/lib/validators";
import validation from "../../validation";
import restAdapter from "../../restAdapter";
import notification from "../../notification";
import VueRecaptcha from "vue-recaptcha";
import Pagination from "../../components/Pagination";
import * as conf from "../../conf.yml";
import RatingSummery from "./RatingSummery";
import {
  hexToRGBA,
  increaseBrightness,
  isLightColor,
} from "../../utils/helper";

export default {
  name: "Review",
  components: {
    VueRecaptcha,
    Pagination,
    RatingSummery,
  },
  props: {
    styleNumber: {
      type: String,
      default: null,
    },
    inquiryId: {
      type: String,
      default: null,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    isCompact: {
      type: Boolean,
      default: false,
    },
    isAddReviewVisible: {
      type: Boolean,
      default: false,
    },
    isAuthorizedToChangeReviewStatus: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    colorCodes: {
      type: Object,
      default: () => ({
        titleColor: "#473068",
        iconColor: "#473068",
        backgroundColor: "#FFFFFF",
      }),
    },
  },
  data: () => {
    const requiredMessage = " is required";
    const maxCommentLength = 1000;
    return {
      conf: conf,
      newReview: {
        name: "",
        email: "",
        is_display_name_as_anonymous: false /* eslint-disable-line @typescript-eslint/camelcase */,
        rating: null,
        comment: "",
        recaptcha: null,
      },
      newReviewWriting: false,
      reviews: [],
      loadingReviews: false,
      reviewsPagination: {
        currentPage: 1,
        totalCount: 1,
      },
      ratings: {
        publicRatingAvg: 0,
        allRatingAvg: 0,
        publicRatingCount: 0,
        allRatingCount: 0,
      },
      summery: {
        allSummery: {
          fiveStars: 0,
          fourStars: 0,
          threeStars: 0,
          twoStars: 0,
          oneStars: 0,
        },
        publicSummery: {
          fiveStars: 0,
          fourStars: 0,
          threeStars: 0,
          twoStars: 0,
          oneStars: 0,
        },
      },
      onceValidated: false,
      captchaRequiredMessage: "Please verify that you are not a robot",
      ratingRequiredMessage: "Please select a rating",
      maxCommentLength: maxCommentLength,
      rules: {
        requiredName: (value) => !!value || "Name" + requiredMessage,
        requiredEmail: (value) => !!value || "Email" + requiredMessage,
        email: (value) => {
          if (!value) {
            return requiredMessage;
          }
          const validEmail = email(validation.emailFormatter(value));
          return validEmail || "Invalid email";
        },
        rating: (value) => !!value || "Please select a rating",
        maxLengthName: (value) =>
          value.length <= 500 || "Name must be less than 500 characters",
        maxLengthEmail: (value) =>
          value.length <= 300 || "Email must be less than 300 characters",
        maxLengthComment: (value) =>
          value.length <= maxCommentLength ||
          `Review must be less than ${maxCommentLength} characters`,
      },
    };
  },
  mounted() {
    this.totalCount(1);
  },
  computed: {
    reviewsDividerColor() {
      const starColorAsRGBA = hexToRGBA(this.colorCodes.backgroundColor);
      return `rgba(${Math.abs(255 - starColorAsRGBA.r)},${Math.abs(
        255 - starColorAsRGBA.g
      )},${Math.abs(255 - starColorAsRGBA.b)},0.2)`;
    },
    reviewTimestampColor() {
      const starColorAsRGBA = hexToRGBA(this.colorCodes.backgroundColor);
      return `rgba(${Math.abs(255 - starColorAsRGBA.r)},${Math.abs(
        255 - starColorAsRGBA.g
      )},${Math.abs(255 - starColorAsRGBA.b)},0.6)`;
    },
    paginationBackgroundColor() {
      const titleColorAsRGBA = hexToRGBA(this.colorCodes.titleColor);
      const isLight = isLightColor(titleColorAsRGBA);
      const brightnessIncreased = increaseBrightness(
        titleColorAsRGBA,
        isLight ? -200 : 200
      );
      return `rgba(${brightnessIncreased.r},${brightnessIncreased.g},${brightnessIncreased.b}, 1)`;
    },
  },
  methods: {
    totalCount(page) {
      this.reviewsPagination.currentPage = page;
      if (this.isAuthorizedToChangeReviewStatus) {
        this.getAllReviews({ page });
      } else {
        this.getPublishedReviews({ page });
      }
    },
    submitReview() {
      if (
        !this.$refs.addReviewForm.validate() ||
        (this.conf.addCaptcha && !this.newReview.recaptcha)
      ) {
        this.onceValidated = true;
        return;
      }
      restAdapter
        .post(`/api/inquiries/trace/${this.styleNumber}/review`, this.newReview)
        .then(() => {
          this.resetForm();
          notification.success(
            "Thank you so much for sharing your experience with us. Your review has been sent to approval."
          );
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status === 429 &&
            error.response.data?.message
          ) {
            notification.error(error.response.data.message);
            return;
          }
          notification.error("Something went wrong. Please try again later");
        });
    },
    resetForm() {
      this.onceValidated = false;
      Object.assign(this.$data.newReview, this.$options.data().newReview);
      this.$refs.addReviewForm.resetValidation();
      this.textAreaCounter(this.newReview.comment);
      this.newReviewWriting = false;
    },
    startReviewWriting() {
      this.newReviewWriting = true;
      // Wait for the form to be rendered before calculating the comment length
      setTimeout(() => {
        this.textAreaCounter(this.newReview.comment);
      }, 100);
    },
    textAreaCounter(value) {
      if (!this.isAddReviewVisible) return;
      const count = value ? value.length : 0;
      const textAreaCounter = document.getElementsByClassName("v-counter")[0];
      if (count > this.maxCommentLength) {
        textAreaCounter.style.color = "#EA7A66";
      } else {
        textAreaCounter.style.color = this.colorCodes.titleColor;
      }
      textAreaCounter.innerHTML = `${count}/${this.maxCommentLength}`;
    },
    onCaptchaVerified(response) {
      this.newReview.recaptcha = response;
    },
    onCaptchaExpired() {
      this.newReview.recaptcha = null;
    },
    getAllReviews(details) {
      restAdapter
        .get(
          `/api/inquiries/trace/${this.inquiryId}/all_reviews?page=${details.page}`
        )
        .then((response) => {
          this.reviewsPagination.totalCount = response.data.total_count;
          this.reviews = response.data.reviews;

          this.ratings.publicRatingCount = response.data.public_count;
          this.ratings.publicRatingAvg = response.data.public_count
            ? Math.round(
                (response.data.public_rating_count * 10) /
                  response.data.public_count
              ) / 10
            : 0;
          this.ratings.allRatingCount = response.data.total_count;
          this.ratings.allRatingAvg = response.data.total_count
            ? Math.round(
                (response.data.all_rating_count * 10) /
                  response.data.total_count
              ) / 10
            : 0;
          this.summery.allSummery = this.summeryFormatter(
            response.data.all_summery
          );
          this.summery.publicSummery = this.summeryFormatter(
            response.data.public_summery
          );
        });
    },
    getPublishedReviews(details) {
      restAdapter
        .get(
          `/api/inquiries/trace/${this.styleNumber}/reviews?page=${details.page}`
        )
        .then((response) => {
          this.reviewsPagination.totalCount = response.data.total_count;
          this.reviews = response.data.reviews;
          this.ratings.publicRatingCount = response.data.total_count;
          this.ratings.publicRatingAvg = response.data.total_count
            ? Math.round(
                (response.data.rating_count * 10) / response.data.total_count
              ) / 10
            : 0;
          this.summery.publicSummery = this.summeryFormatter(
            response.data.summery
          );
        });
    },
    updateReviewStatus(review) {
      const confirmEdit = confirm(
        `Are you sure you want to make this review ${
          review.is_private ? "private" : "public"
        }?`
      );
      if (!confirmEdit) {
        const index = this.reviews.findIndex((item) => item.id === review.id);
        // Wait until the radio button is updated
        setTimeout(() => {
          /* eslint-disable-next-line @typescript-eslint/camelcase */
          this.reviews[index].is_private = review.is_private ? 0 : 1;
        }, 100);
        return;
      }
      restAdapter
        .put(`/api/inquiries/trace/${this.inquiryId}/reviews/${review.id}`, {
          /* eslint-disable-next-line @typescript-eslint/camelcase */
          is_private: review.is_private,
        })
        .then(() => {
          if (!review.published) {
            const index = this.reviews.findIndex(
              (item) => item.id === review.id
            );
            this.reviews[index].published = true;
          }
          this.getAllReviews({ page: this.reviewsPagination.currentPage });
          notification.success("Review status updated successfully");
        });
    },
    deleteReview(review) {
      const confirmDelete = confirm(
        "Are you sure you want to delete this review?"
      );
      if (!confirmDelete) return;
      restAdapter
        .delete(`/api/inquiries/trace/${this.inquiryId}/reviews/${review.id}`)
        .then(() => {
          const pageToGet =
            this.reviews.length === 1
              ? this.reviewsPagination.currentPage - 1
              : this.reviewsPagination.currentPage;
          this.reviewsPagination.currentPage = pageToGet;
          this.getAllReviews({ page: pageToGet });
          notification.success("Review deleted successfully");
        });
    },
    summeryFormatter(values) {
      return {
        fiveStars: values?.fiveStars || 0,
        fourStars: values?.fourStars || 0,
        threeStars: values?.threeStars || 0,
        twoStars: values?.twoStars || 0,
        oneStars: values?.oneStars || 0,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.review-component-container {
  margin-top: 30px;
}
.add-review-form {
  margin: 30px 0px 20px 0px;
}
.write-review-button {
  margin: 10px 0px 20px -8px;
}
.additional-inputs {
  margin-bottom: 20px;
}
.rating-container {
  display: flex;
  align-items: center;
  margin-left: -3px;
}
.rating-details {
  margin-left: 10px;
  font-size: 16px;
}
.custom-validation-message {
  font-size: 12px;
  color: $danger;
  min-height: 14px;
  margin-bottom: 8px;
}
.v-rating-add::v-deep .mdi-star {
  color: var(--star-color) !important;
  font-size: 34px;
}
.v-rating-add::v-deep .v-icon {
  padding: 0 3px !important;
}
.v-rating-add::v-deep .mdi-star-outline {
  color: var(--star-color) !important;
  font-size: 34px;
}
.v-rating-add::v-deep .mdi-star-half-full {
  color: var(--star-color) !important;
  font-size: 34px;
}
.v-rating-read-only {
  width: fit-content;
  margin-left: -5px;
}
.v-rating-read-only::v-deep .mdi-star {
  font-size: 24px;
}
.v-rating-read-only::v-deep .mdi-star-outline {
  font-size: 24px;
}
.v-rating-read-only::v-deep .mdi-star-half-full {
  font-size: 24px;
}
.text-field::v-deep .v-label--active {
  margin-left: -12px;
  margin-top: -3px;
}
.text-area::v-deep .v-text-field--outlined .v-label--active {
  margin-left: -22px;
  margin-top: -3px;
}
.text-area::v-deep .v-input__slot {
  margin-bottom: 4px;
}
.text-area::v-deep .v-text-field__details {
  margin-bottom: 0px;
}
.text-area::v-deep .v-label {
  margin-top: -9px;
}
.text-area::v-deep textarea {
  color: var(--review-input-color) !important;
}
.text-field::v-deep .v-messages__message {
  margin-left: -12px;
  color: var(--review-input-color);
}
.text-field::v-deep .error--text .v-messages__message {
  color: $danger !important;
}
.text-field::v-deep input,
.text-field::v-deep label {
  color: var(--review-input-color) !important;
}
.v-text-field--outlined::v-deep fieldset {
  border: 1px solid var(--review-input-color) !important;
  color: var(--review-input-color);
}
.v-input--is-focused::v-deep fieldset,
.v-input--has-state::v-deep fieldset {
  border: 2px solid var(--review-input-color) !important;
}
.anonymous-checkbox::v-deep label {
  color: var(--review-input-color) !important;
}
.v-input--checkbox.v-input--dense {
  margin-top: 0px;
}
.anonymous-checkbox::v-deep input {
  color: var(--review-input-color) !important;
}
.anonymous-checkbox::v-deep .v-input--selection-controls__input {
  width: unset;
  margin-left: -2px;
  margin-right: 5px;
}
.anonymous-checkbox::v-deep .v-icon.v-icon {
  color: var(--review-input-color);
}
.anonymous-checkbox::v-deep .v-input--selection-controls__ripple {
  display: none;
}
.submitButtonContainer {
  display: flex;
  justify-content: flex-end;
}
.submitButtonContainer::v-deep .md-button {
  margin: 0px;
}
.multiple-ratings-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-left: 0px;
}
.review-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  text-align: left;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.rating-item {
  max-width: 100%;
  overflow-wrap: break-word;
}
.review-name {
  font-size: 12px;
}
.review-email {
  color: #808080;
  font-size: 12px;
}
.review-comment {
  font-weight: 400;
  font-size: 16px;
  margin-top: 5px;
}
.review-date {
  font-size: 12px;
}
.action-panel-container {
  margin-right: 15px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.action-panel {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  align-items: center;
}
.delete-button {
  margin-left: 10px;
  cursor: pointer;
}
.delete-icon {
  color: $danger;
  font-size: 20px;
}
.no-reviews {
  font-size: 15px;
  margin-top: -30px;
}
.review-list-container {
  padding-top: 20px;
}
</style>
