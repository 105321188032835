<template>
  <div style="width: 100%">
    <div
      :class="`compact-item-header ${isCompact ? 'clickable-header' : ''}`"
      :style="{ backgroundColor: colorCodes.backgroundColor }"
      @click="toggleExpand"
    >
      <div
        v-if="!isMobile"
        class="icon-container"
        :style="{ ...(isCompact ? { flex: 1 } : {}) }"
      >
        <ellipse-icon
          :width="'59px'"
          :height="'59px'"
          border="2px"
          :variant="colorCodes.iconColor"
          :fill="colorCodes.backgroundColor"
        >
          <component :is="icon" :fill="colorCodes.iconColor" />
        </ellipse-icon>
        <span
          class="more-info-title"
          :style="{
            color: colorCodes.titleColor,
            borderBottom: `1px solid ${colorCodes.iconColor}`,
            ...(isCompact ? { width: '100%' } : {}),
          }"
          >{{ title }}</span
        >
      </div>
      <div
        v-else
        class="icon-container title-underline"
        :style="{
          borderBottom: `1px solid ${colorCodes.iconColor}`,
          ...(isCompact ? { flex: 1 } : {}),
        }"
      >
        <component v-if="!iconSize" :is="icon" :fill="colorCodes.iconColor" />
        <component
          v-else
          :is="icon"
          :fill="colorCodes.iconColor"
          :width="iconSize"
          :height="iconSize"
        />
        <span
          class="more-info-title-mobile"
          :style="{
            color: colorCodes.titleColor,
            ...(isCompact ? { width: '100%' } : {}),
          }"
          >{{ title }}</span
        >
      </div>
      <div v-if="isCompact" class="expand-button">
        <v-icon class="expand-icon" :style="{ color: colorCodes.iconColor }">{{
          expanded ? "mdi-minus" : "mdi-plus"
        }}</v-icon>
      </div>
    </div>
    <slot v-if="!isCompact || expanded" />
  </div>
</template>

<script>
export default {
  name: "TraceCompactHeader",
  props: {
    title: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    iconSize: {
      type: [String, Number],
      default: null,
    },
    colorCodes: {
      type: Object,
      default: () => ({
        titleColor: "#473068",
        fontColor: "#473068",
        iconColor: "#70d0ce",
        verifiedColor: "#473068",
        unverifiedColor: "#ea7a66",
        backgroundColor: "#ffffff",
      }),
    },
    isCompact: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    expanded: false,
  }),
  methods: {
    toggleExpand() {
      if (this.isCompact) {
        this.expanded = !this.expanded;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.compact-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 0 0;
  position: sticky;
  top: 0;
  z-index: 12;
}
.icon-container {
  display: flex;
  align-items: center;
}
.more-info-title-mobile {
  margin-left: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
}
.more-info-title {
  @extend .more-info-title-mobile;
  text-align: left;
  max-width: calc(100% - 74px);
}
.title-underline {
  width: fit-content;
}
.expand-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
}
.expand-icon {
  font-size: 30px;
}
.clickable-header {
  cursor: pointer;
}
</style>
